<template>
    <div class="press-release-block">
        <h6>{{data.time}}</h6>
        <h4>
            <b-link @click="onclick(data.link)" title="">{{data.text}}</b-link>
        </h4>
    </div>
</template>

<script>
    import mixpanel from "mixpanel-browser";
    export default {
        props: {
            data: Object,
        },
        methods: {
            onclick(link) {
                this.$gtm.trackEvent({
                  event: 'View Press Release',
                  category: 'view',
                  action: 'click',
                  label: 'View Press Release',
                  value: this.data.text
                });
                try {
                  mixpanel.track('view-press-release', {
                    'link': link,
                    article: this.data.text
                  })
                } catch (e) {
                  console.log(e);
                }
                window.open(link, "_self")
            }
        }
    }

</script>
